<template>
	<div>
		<a-modal title="编辑打印机" :width="480" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<div class="mt20">
				<a-spin :spinning="loading">
					<div class="edit-printer-select ">
						<a-select style="width: 420px;" class="mb20" v-model="form.app_type">
							<a-select-option :value="'yly'">易联云</a-select-option>
							<a-select-option :value="'feie'">飞鹅</a-select-option>
						</a-select>
						<div class="edit-printer-select-prefix flex alcenter center">类型</div>
					</div>
					
					
					<!-- 易联云打印机 -->
					<div v-if="form.app_type=='yly'">
						<a-input style="width: 420px;" class="mb20" placeholder="请输入终端号" v-model="form.machine_code" :maxLength="20">
							<div style="width: 80px;" slot="prefix" class="input-addon-before text-center">
								终端号
							</div>
						</a-input>
						<a-input style="width: 420px;" class="mb20" placeholder="请输入终端密钥" v-model="form.msign" :maxLength="20">
							<div style="width: 80px;" slot="prefix" class="input-addon-before text-center">
								终端密钥
							</div>
						</a-input>
					</div>
					
					
					<!-- 飞鹅打印机 -->
					<div v-if="form.app_type=='feie'">
						<a-input style="width: 420px;" class="mb20" placeholder="请输入打印机编号" v-model="form.print_sn" :maxLength="9">
							<div style="width: 80px;" slot="prefix" class="input-addon-before text-center">
								打印机编号
							</div>
						</a-input>
						<a-input style="width: 420px;" class="mb20" placeholder="请输入识别码" v-model="form.print_key" :maxLength="8">
							<div style="width: 80px;" slot="prefix" class="input-addon-before text-center">
								识别码
							</div>
						</a-input>
					</div>
					
					
					<a-input style="width: 420px;" class="mb20" placeholder="请输入打印机名称" v-model="form.printer_name" :maxLength="20">
						<div style="width: 80px;" slot="prefix" class="input-addon-before text-center">
							打印机名称
						</div>
					</a-input>
					
					<div class="mt20">
						<div class="flex alcenter">
							<div class="ft14 ftw400 cl-info">设置权限</div>
							<div class="ft12 ftw400 cl-notice ml8">可多选</div>
						</div>
						<div class="mt10 flex wrap">
							<template v-for="(item,index) in printer_jurisdiction">
								<div @click="removeJurisdiction(index)" v-if="form.jurisdiction.indexOf(item.iden) != -1" class="equipment-index-jurisdiction-item active">{{item.name}}<i
									 class="iconfont iconicon_choosed equipment-index-jurisdiction-item-active" /></div>
								<div @click="addJurisdiction(index)" v-else class="equipment-index-jurisdiction-item">{{item.name}}</div>
							</template>
						</div>
					</div>
				</a-spin>
				
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			printer_id: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				store:[],
				form: {
					app_type:'yly',
					print_sn:'',
					print_key:'',
					machine_code: '',
					msign: '',
					printer_name: '',
					jurisdiction: [],
				},
				printer_jurisdiction: [
					{
						iden:'buy',
						name:'买单'
					},
					{
						iden:'orderWriteOff',
						name:'订单核销'
					},
					{
						iden:'storeOrderWriteOff',
						name:'囤货订单核销'
					},
					{
						iden:'snapUpOrderWriteOff',
						name:'抢购订单核销'
					},
					{
						iden:'onceCardOrderWriteOff',
						name:'次卡订单核销'
					},
					{
						iden:'appointment',
						name:'预约'
					},
					{
						iden:'cashOrder',
						name:'收银订单'
					},
				],
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPrinter',{
					printer_id:this.printer_id,
				}).then(res=>{
					if(res.data){
						this.form=res.data;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleCancel() {
				this.$emit('cancel');
			},
			onSubmit() {
				if(this.form.app_type=='yly'){
					this.YlyPrinter();
				}else{
					this.FeiePrinter();
				}
			},
			YlyPrinter(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/bindYlyPrinter',{
					printer_id:this.printer_id,
					machine_code:this.form.machine_code,
					msign:this.form.msign,
					printer_name:this.form.printer_name,
					jurisdiction:this.form.jurisdiction,
				}).then(res=>{
					this.confirmLoading=false;
					this.$message.success('操作成功',1,()=>{
						this.$emit('ok');
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			FeiePrinter(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/bindFeiePrinter',{
					printer_id:this.printer_id,
					print_sn:this.form.print_sn,
					print_key:this.form.print_key,
					printer_name:this.form.printer_name,
					jurisdiction:this.form.jurisdiction,
				}).then(res=>{
					this.confirmLoading=false;
					this.$message.success('操作成功',1,()=>{
						this.$emit('ok');
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			/**
			 * 选中权限
			 * @param {Object} index
			 */
			addJurisdiction(index) {
				if (this.form.jurisdiction.indexOf(this.printer_jurisdiction[index].iden) != -1) {
					this.$message.error("已有这个权限");
				}
				this.form.jurisdiction.push(this.printer_jurisdiction[index].iden);
				console.log(this.form.jurisdiction);
			},
			
			/**
			 * 取消选择权限
			 * @param {Object} index
			 */
			removeJurisdiction(index) {
				let startIndex = this.form.jurisdiction.indexOf(this.printer_jurisdiction[index].iden);
				if (startIndex == -1) {
					this.$message.error("已没有这个权限");
				}
				this.form.jurisdiction.splice(startIndex, 1);
			},
		}
	}
</script>

<style>
	.ant-input-affix-wrapper .ant-input:not(:first-child) {
		padding-left: 100px;
	}

	.edit-printer-select {
		position: relative;
	}

	.edit-printer-select .ant-select-selection-selected-value {
		padding-left: 100px;
		float: left;
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		color: #000000;
		text-overflow: ellipsis;
	}

	.edit-printer-select-prefix {
		position: absolute;
		top: 1px;
		height: 30px;
		line-height: 30px;
		margin-left: 11px;
		width: 80px;
	}
	
	.equipment-index-jurisdiction-item {
		padding: 4px 8px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #C5CADB;
		margin-right: 10px;
		margin-bottom: 10px;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: #2E2F33;
		cursor: pointer;
		position: relative;
	}
	
	.equipment-index-jurisdiction-item.active {
		border: 1px solid #5333FF;
		color: #5333FF;
	}
	
	.equipment-index-jurisdiction-item:hover {
		border: 1px solid #5333FF;
		color: #5333FF;
	}
	
	.equipment-index-jurisdiction-item-active {
		position: absolute;
		color: #5333FF;
		top: -9px;
		right: 0;
	}
</style>
